<template>
  <div class="order-card_wrapper">
    <div class="block__flex block__align-center order-card_margin">
      <div class="order-card_title">Заказ № 12356</div>
      <div class="component__margin-left">
        <checkbox :value="true" />
      </div>
    </div>
    <div class="block block__between order-card_margin">
      <div class="block__flex block__align-top">
        <div>
          <img src="@/assets/pictures/basket/image 52.png" class="order-card_image" />
        </div>
        <div class="component__margin-left">
          <div class="block__flex block__align-center component__margin-bottom">
            <Stars :editable="false" :rating="4.6" size="small" />
            <div class="component__margin-left order-card_rating">4.6</div>
          </div>
          <div class="order-card_name">Инновационная лаборатория «Innolab»..</div>
          <div>
            <span class="order-card_place order-card_place_label">
              Местоположение производства:
            </span>
            <span class="order-card_place order-card_place_value">
               Россия, г. Москва
            </span>
          </div>
        </div>
      </div>
      <div class="block__flex block__right">
        <div class="component__margin-left order-card_discount">
          <Discount />
          <div class="component__margin-left">
            <div class="order-card_discount_title">Скидка:</div>
            <div class="order-card_discount_value">12%</div>
          </div>
        </div>
        <div class="component__margin-left order-card_button">
          <Print />
        </div>
        <div class="component__margin-left order-card_button">
          <Trash />
        </div>
      </div>
    </div>
    <div class="block block__between block__align-center order-card_margin">
      <div>
        <div>
          <span class="order-card_min-summ_label">Минимальная сумма заказа:</span>
          <span class="order-card_min-summ_value"> 10 000,00 ₽</span>
        </div>
        <div>
          <span class="order-card_summ_label">(сумма Вашего заказа:</span>
          <span class="order-card_summ_value"> 19 418,43 ₽</span>
          <span class="order-card_summ_label">)</span>
        </div>
      </div>
      <div>
        <div class="order-card_total-summ_label">Итого сумма заказа:</div>
        <div class="order-card_total-summ_value">10 000,00 ₽*</div>
      </div>
    </div>
    <div>
      <div v-for="(part, index) in parts" v-bind:key="index" class="order-card_margin-list">
        <PartCard :part="part" />
      </div>
    </div>
  </div>
</template>

<script>
const Checkbox = () => import('@/components/checkbox/CheckBoxI5.vue');
const Stars = () => import('@/components/stars/Stars.vue');
const Trash = () => import('@/assets/pictures/basket/trash.svg');
const Print = () => import('@/assets/pictures/basket/print.svg');
const Discount = () => import('@/assets/pictures/basket/discount.svg');
const PartCard = () => import('./PartCard.vue');

export default {
  name: 'OrderCard',
  props: {
    order: Object,
  },
  data() {
    return {
      parts: [1, 2],
    };
  },
  components: {
    Checkbox,
    Stars,
    Trash,
    Print,
    Discount,
    PartCard,
  },
};
</script>
